const template = [
'theme-name',
'--title-bar',
'--menu-bar',
'--menu-font',
'--menu-hl',
'--name-font',
'--theme-font',
'--main-card',
'--dev-desc',
'--retro-btn',
'--shadows',
'--card-shadows',
'--tuifield',
'--scroll-back',
'--img-shadow',
'--background',
'--borders',
'--tuifield-fnb',
'--skill-stroke'
]

const allThemes = [

  {
    themeName: "Default",
    titlebar: "var(--def-title-bar)",
    menubar: "var(--def-menu-bar)",
    menufont: "var(--def-menu-font)",
    menuHL: "var(--def-menu-hl)",
    nameFont: "var(--def-name-font)",
    themeFont: "#ffffff",
    maincard: "var(--def-main-card)",
    devDesc: "var(--def-dev-desc)",
    retroBtn: "var(--def-retro-btn)",
    shadows: "var(--def-shadows)",
    cardShadows: "var(--def-card-shadows)",
    tuifield: "var(--def-tuifield)",
    scrollBack: "var(--def-scroll-back)",
    imgShadow: "var(--def-img-shadow)",
    background: "var(--def-background)",
    borders: "var(--def-borders)",
    tuifieldFnb: "var(--def-tuifield-fnb)",
    skillStroke: "var(--def-skill-stroke)"
  },
  {
    themeName: "Monochromatic",
    titlebar: "var(--dos-black)",
    menubar: "var(--grey)",
    menufont: "var(--white)",
    menuHL: "var(--white)",
    nameFont: "var(--white)",
    themeFont: "#ffffff",
    maincard: "var(--none)",
    devDesc: "var(--grey)",
    retroBtn: "var(--grey)",
    shadows: "var(--white)",
    cardShadows: "var(--white)",
    tuifield: "var(--black)",
    scrollBack: "var(--grey)",
    imgShadow: "var(--grey)",
    background: "var(--def-background)",
    borders: "var(--white)",
    tuifieldFnb: "var(--white)",
    skillStroke: "var(--def-skill-stroke)"

  },
  {
    themeName: "Pastel Robot",
    titlebar: "var(--dos-ligth-red)",
    menubar: "var(--dos-yellow)",
    menufont: "var(--dos-yellow)",
    menuHL: "var(--dos-green)",
    nameFont: "var(--dos-green)",
    themeFont: "#000000",
    maincard: "var(--dos-yellow)",
    devDesc: "var(--dos-ligth-red)",
    retroBtn: "var(--dos-green)",
    shadows: "black",
    cardShadows: "var(--dos-ligth-red)",
    tuifield: "var(--dos-green)",
    scrollBack: "var(--dos-green)",
    imgShadow: "black",
    background: "#111111c8",
    borders: "black",
    tuifieldFnb: "black",
    skillStroke: "var(--dos-ligth-red)"

  },
  {
    themeName: "Purple Haze",
    titlebar: "#7F669D",
    menubar: "#DEBACE",
    menufont: "var(--dos-black)",
    menuHL: "#FBFACD",
    nameFont: "black",
    themeFont: "#7F669D",
    maincard: "#DEBACE",
    devDesc: "#7F669D",
    retroBtn: "#7F669D",
    shadows: "black",
    cardShadows: "black",
    tuifield: "#DEBACE",
    scrollBack: "#DEBACE",
    imgShadow: "black",
    background: "#7F669Dc5",
    borders: "black",
    tuifieldFnb: "black",
    skillStroke: "var(--def-skill-stroke)"

/*     tuifieldFnb: "#7F669D" */

  },
  {
    themeName: "Green Hills",
    titlebar: "#003B00",
    menubar: "#008F11",
    menufont: "#0D0208",
    menuHL: "var(--dos-yellow)",
    nameFont: "#008F11",
    themeFont: "#ffff50",
    maincard: "#003B00",
    devDesc: "#00FF41",
    retroBtn: "var(--dos-yellow)",
    shadows: "#0D0208",
    cardShadows: "#008F11",
    tuifield: "#003B00",
    scrollBack: "#003B00",
    imgShadow: "black",
    background: "var(--def-background)",
    borders: "#008F11",
    tuifieldFnb: "var(--dos-yellow)",   
    skillStroke: "var(--def-skill-stroke)"


  }
];

const themeSelector = (index)=>{

    let cssVars = document.documentElement.style;
  

    Object.keys(allThemes[index]).map((keyName, idx)=>{
        //console.log(keyName)
        if(idx!==0) cssVars.setProperty(template[idx],allThemes[index][keyName])
        //cssVars.setProperty(template[idx],`var(${allThemes[index][keyName]})`);
  
    })
}

module.exports = {themeSelector, allThemes}

