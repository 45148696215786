const headersTxts =
{
  about:
  {
    eng:'\\ A B O U T ',
    spa:'\\ S O B R E _ M I '
  },
  projects:
  {
    eng:'\\ P R O J E C T S ',
    spa:'\\ P R O Y E C T O S '
  },
  skills:
  {
    eng:'\\ S K I L L S ',
    spa:'\\ T E C N O L O G I A S '
  },
  contact:
  {
    eng:'\\ C O N T A C T ',
    spa:'\\ C O N T A C T O '
  },
}

const contactTxts = 
    {
      name:
      {
        legend:
        {
          eng:'Name',
          spa:'Nombre'
        },
        placeholder:
        {
          eng:'Input your name',
          spa:'Ingresa tu nombre'
        },
      },
      mail:
      {
        legend:
        {
          eng:'E-mail',
          spa:'Correo'
        },
        placeholder:
        {
          eng:'Input your e-mail',
          spa:'Ingresa tu correo electrónico'
        },
      },
      msg:
      {
        legend:
        {
          eng:'Message',
          spa:'Mensaje'
        },
        placeholder:
        {
          eng:'Input your message',
          spa:'Dejame tu mensaje'
        },
      },
      send:
      {
        label:
        {
          eng:'SEND',
          spa:'ENVIAR'
        },
      },
      title:       
      {
        eng:'Contact Me',
        spa:'Contactame'
      },
      checkbox:       
      {
        eng:'Would you like to request a copy of my CV?',
        spa:'Quieres solicitar una copia de mi CV?'
      },
    }

    const prjTxts = [
      {
        name: "RetroUI - Portfolio",
        description: "My personal portfolio designed with a retro look inspired by old text-based user interfaces and file managers from the early 90s.",
        descriptionSp: "Mi portfolio personal diseñado con un aspecto retro inspirado en antiguas interfaces de usuario y administradores de archivos de principios de los 90.",
        imgPath: "prjcts-retroport",
        vidPath:"prjcts-retroport",
        stack: "React ● CSS ● MUI"
      },
      {
        name: "Xavia IoT",
        description: "Professional project developed along a 5 dev team over a 4-week timeline. The final result is a web app able to manage IoT devices and receive real data directly into our database. This data is instantly visualized over multiple graphs, charts and maps on the app.",
        descriptionSp: "Proyecto profesional desarrollado con un equipo de 5 desarrolladores con un timeline de 4 semanas. El resultado final es una aplicación web capaz de administrar dispositivos IoT y recibir datos reales directamente en nuestra base de datos. Estos datos se visualizan instantáneamente en múltiples gráficos, cuadros y mapas en la aplicación",
        imgPath:"prjcts-xavia",
        vidPath:"prjcts-xavia",
        stack: "React.js ● Node.js ● MongoDB ● MUI"
      },
      {
        name: "Pasion Argenta E-Commerce",
        description: "Group project, we developed a web app that simulates an e-commerce site. Available features include: register, sign in, email notifications, cookie-based auth, search with multiple filters, favorites, shopping cart, orders management, an advanced admin dashboard and more",
        descriptionSp: "Proyecto grupal en el cual desarrollamos una aplicación web que simula un sitio e-commerce. Las características disponibles incluyen: registro, inicio de sesión, notificaciones por correo electrónico, autenticación basada en cookies, búsqueda con múltiples filtros, favoritos, carrito de compras, gestión de pedidos, un avanzado admin dashboard y mas",
        imgPath: "prjcts-pasionargenta",
        vidPath:"prjcts-pasionargenta",
        stack: "React.js ● Node.js ● MongoDB ● MUI"
      },
      {
        name: "OldTerminal Typer",
        description: "A standalone desktop application made in unity, where you can emulate an old terminal computer typing animation with multiple styles and effects. This app was developed for the musician San Tarcisio, to be used on his music videos.",
        descriptionSp: "Aplicación de escritorio desarrollada en Unity, donde puedes emular la animación de escritura de una computadora antigua y con múltiples estilos y efectos. Esta aplicación fue desarrollada para el músico San Tarcisio, para ser utilizada en sus videos musicales",
        imgPath: "prjcts-oldtyper",
        vidPath:"prjcts-oldtyper",
        stack: "Unity"
      },
      {
        name: "Space Dementia <In Development>",
        description: "A multiplayer action couch game to play amonng... most likely against your friends. Join in being an astronaut who went nuts and attack everyone else with all kinds of conventional and unconventional weapons, all in a retro styled pixel art design.",
        descriptionSp: "Un juego multijugador de acción para jugar junto a tus ami... mejor dicho contra tus amigos. Súmate y conviertete en un astronauta que se volvió loco y ataca a todos los demás usando todo tipo de armas convencionales y no convencionales, con un aspecto pixel art retro.",
        imgPath:"prjcts-spacedementia",
        vidPath:"prjcts-spacedementia",
        stack: "Unity ● Aseprite"
      }
    
    ]
    const infoTxts = 
    {
      eng:"I'm a highly motivated and detail-oriented professional who's always looking to improve and grow in his career. I'm a problem solver by nature and I have a knack for coming up with creative solutions for any challenge",
      spa:"Soy un profesional altamente motivado y con atención al detalle que siempre busca mejorar y crecer en su carrera. Soy un solucionador de problemas por naturaleza y tengo pasión por encontrar soluciones creativas para los desafíos que se presenten"

    }
    const footnoteTxts = 
    {
      eng:"Designed and developed by Ln.Loza",
      spa:"Diseñado y desarrollado por Ln.Loza"
      
    }

    module.exports = {contactTxts, prjTxts, infoTxts, footnoteTxts, headersTxts}