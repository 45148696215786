import React from "react";
import ProjectsCard from "./cards/ProjectsCard";
//import ReactTooltip from "react-tooltip";
import { ThemeContext } from "../contexts/themeContext";
import { headersTxts } from "../utils/Texts";
import { useContext } from "react";

const Projects = ({ openMenu }) => {
  const { options } = useContext(ThemeContext);
  return (
    <>
      <div className={`portfolio-section open-menu-${openMenu}`}>
        <div id="projects" className="headers">
          {!options[2] ? headersTxts.projects.eng : headersTxts.projects.spa}
          <span className="caret-blinking">█</span>
        </div>
        <ProjectsCard />
      </div>
    </>
  );
};

export default Projects;
