import "./App.css";
import { Routes, Route } from 'react-router-dom';
import Portfolio from "./components/Portfolio";
import PortfolioScroll from "./components/PortfolioScroll";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { ThemeContext } from "./contexts/themeContext";
import { useState } from "react";
import Intro from "./components/Intro";
import { allThemes } from "./theme/themes";
import HiddenPage from './components/extraHandlers/HiddenPage';
import QRDoorbell from './components/extraHandlers/QRDoorBell';

function App() {
  const [portfolio, setPortfolio] = useState(false);
  const [options, setOptions] = useState([0, 0, 0]);
  const theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: allThemes[options[1]].themeFont,
      },
    },
  });
  const openPortfolio = () => {
    setPortfolio(!portfolio);
  };

  return (
      <Routes>
        <Route exact path="/" element={
          <ThemeContext.Provider value={{ options, setOptions }}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Intro onPortfolio={openPortfolio} onClose={openPortfolio} />
              {portfolio ? (
                !options[0] ? (
                  <PortfolioScroll onClose={openPortfolio} />
                ) : (
                  <Portfolio onClose={openPortfolio} />
                )
              ) : (
                ""
              )}
            </ThemeProvider>
          </ThemeContext.Provider>
        } />
         <Route path="/hidden" element={<HiddenPage />} />
         <Route path="/qrdoorbell" element={<QRDoorbell />} />
      </Routes>
  );
}
export default App;
