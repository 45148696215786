import React from "react";
import SkillsCard from "./cards/SkillsCard";
import { ThemeContext } from "../contexts/themeContext";
import { headersTxts } from "../utils/Texts";
import { useContext } from "react";

const Skills = ({ openMenu }) => {
  const { options } = useContext(ThemeContext);
  return (
    <div className={`portfolio-section open-menu-${openMenu}`}>
      <div id="skills" className="headers">
        {!options[2] ? headersTxts.skills.eng : headersTxts.skills.spa}
        <span className="caret-blinking">█</span>
      </div>
      <SkillsCard />
    </div>
  );
};

export default Skills;
