import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { useInView } from "react-intersection-observer";

import {
  SiJavascript,
  SiCsharp,
  SiPhp,
  SiReact,
  SiRedux,
  SiHtml5,
  SiCss3,
  SiMongodb,
  SiExpress,
  SiPostgresql,
  SiSequelize,
  SiGithub,
  SiUnity,
  SiBlender,
  SiAseprite,
} from "react-icons/si";
import { FaNodeJs } from "react-icons/fa";

export default function SkillsCard() {
  const [skills] = useState([
    {
      category: "Languages",
      title: ["Javascript", "C#", "PHP"],
      logo: [
        <SiJavascript className="logo-btn" />,
        <SiCsharp className="logo-btn" />,
        <SiPhp className="logo-btn" />,
      ],
    },

    {
      category: "Front-end",
      title: ["React", "Redux", "HTML5", "CSS3"],
      logo: [
        <SiReact className="logo-btn" />,
        <SiRedux className="logo-btn" />,
        <SiHtml5 className="logo-btn" />,
        <SiCss3 className="logo-btn" />,
      ],
    },

    {
      category: "Back-end",
      title: ["MongoDB", "NodeJS", "Express", "PostgreSQL", "Sequelize"],
      logo: [
        <SiMongodb className="logo-btn" />,
        <FaNodeJs className="logo-btn" />,
        <SiExpress className="logo-btn" />,
        <SiPostgresql className="logo-btn" />,
        <SiSequelize className="logo-btn" />,
      ],
    },

    {
      category: "Game Development",
      title: ["Unity", "Blender", "Aseprite"],
      logo: [
        <SiUnity className="logo-btn" />,
        <SiBlender className="logo-btn" />,
        <SiAseprite className="logo-btn" />,
      ],
    },
    {
      category: "Others",
      title: ["Github"],
      logo: [<SiGithub className="logo-btn" />],
    },
  ]);

  const {
    ref: skillsSection,
    inView: skillsVisible,
    entry,
  } = useInView({
    threshold: 1,
  });

  return (
    <div
      ref={skillsSection}
      className={skillsVisible ? "skills from-center" : "skills"}
      id="skills"
    >
      {skills.map((skill, index) => (
        <section className="panelout my-panel" key={"ds" + index}>
          <fieldset className="tui-fieldset">
            <legend>{skill.category}</legend>
            <div>
              <ul className="list-inline skill-icons">
                {skill.logo.map((logo, index) => (
                  <Tooltip
                    title={
                      <h2 /* style={{ color: "white" }} */>
                        {skill.title[index]}
                      </h2>
                    }
                    TransitionComponent={Zoom}
                    placement="top"
                    arrow
                    key={"tt" + index}
                  >
                    <li className="list-inline-item">{logo}</li>
                  </Tooltip>
                ))}
              </ul>
            </div>
          </fieldset>
        </section>
      ))}
    </div>
  );
}
