import React from "react";
import ContactCard from "./cards/ContactCard";
import { ThemeContext } from "../contexts/themeContext";
import { headersTxts } from "../utils/Texts";
import { useContext } from "react";

const Contact = ({ openMenu }) => {
  const { options } = useContext(ThemeContext);
  return (
    <div className={`portfolio-section open-menu-${openMenu}`}>
      <div id="contact" className="headers">
        {!options[2] ? headersTxts.contact.eng : headersTxts.contact.spa}
        <span className="caret-blinking">█</span>
      </div>
      <ContactCard />
    </div>
  );
};

export default Contact;
