import CloseIcon from "@mui/icons-material/Close";
import { useContext, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { HashLink as Link } from "react-router-hash-link";
import { themeSelector, allThemes } from "../theme/themes";
import { ThemeContext } from "../contexts/themeContext";

const Nav = ({ openMenu, onClose, onChange, page, showMenu }) => {
  const { options, setOptions } = useContext(ThemeContext);

  useEffect(() => {
    document.addEventListener("keydown", keysHandler);
    return () => {
      document.removeEventListener("keydown", keysHandler);
    };
  });

  function changeTheme() {
    let index = options[1] < allThemes.length - 1 ? options[1] + 1 : 0;
    setOptions([options[0], index, options[2]]);
    themeSelector(index);
  }

  const keysHandler = (e) => {
    if (e.key === "F2") changeTheme();
    if (e.target.nodeName === "INPUT" || e.target.nodeName === "TEXTAREA")
      return;
    if (e.target.isContentEditable) return;
    let HkSkills = !options[2] ? "s" : "t";
    let HkAbout = !options[2] ? "a" : "s";
    switch (e.key.toLowerCase()) {
      case HkAbout:
        onChange("about");
        document
          .getElementById("about")
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case "p":
        onChange("projects");
        document
          .getElementById("projects")
          ?.scrollIntoView({ behavior: "smooth" });
        break;
      case HkSkills:
        onChange("skills");
        document
          .getElementById("skills")
          ?.scrollIntoView({ behavior: "smooth" });
        break;
      case "c":
        onChange("contact");
        document
          .getElementById("contact")
          ?.scrollIntoView({ behavior: "smooth" });
        break;
    }
  };

  return (
    <>
      <nav className={`nav-bar menu-open-${openMenu}`}>
        <MenuIcon
          className={`burger-btn hide-menu-btns-${openMenu} hide-hmb`}
          onClick={showMenu}
        />
        <div
          className={`title-bar-but-container hide-menu-btns-all-${!openMenu}`}
        >
          <MenuIcon className="burger-btn" onClick={showMenu} />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
          >
            <CloseIcon className="close-btn" onClick={onClose} />
          </div>
        </div>
        <ul className="nav-btns">
          <li
            className={`${page === "about" ? "nav-on" : ""}`}
            onClick={() => onChange("about")}
          >
            <Link to="#about" smooth style={{ textDecoration: "none" }}>
              <span className={`show-opt-${openMenu}`}>
                <h1 className="hotkey">{!options[2] ? "About" : "Sobre mí"}</h1>
              </span>
            </Link>
          </li>
          <li
            className={`${page === "projects" ? "nav-on" : ""}`}
            onClick={() => onChange("projects")}
          >
            <Link to="#projects" smooth style={{ textDecoration: "none" }}>
              <span className={`show-opt-${openMenu}`}>
                <h1 className="hotkey">
                  {" "}
                  {!options[2] ? "Projects" : "Proyectos"}
                </h1>
              </span>
            </Link>
          </li>
          <li
            className={`${page === "skills" ? "nav-on" : ""}`}
            onClick={() => onChange("skills")}
          >
            <Link to="#skills" smooth style={{ textDecoration: "none" }}>
              <span className={`show-opt-${openMenu}`}>
                <h1 className="hotkey">
                  {!options[2] ? "Skills" : "Tecnologías"}
                </h1>
              </span>
            </Link>
          </li>
          <li
            className={`${page === "contact" ? "nav-on" : ""}`}
            onClick={() => onChange("contact")}
          >
            <Link to="#contact" smooth style={{ textDecoration: "none" }}>
              <span className={`show-opt-${openMenu}`}>
                <h1 className="hotkey">
                  {!options[2] ? "Contact" : "Contacto"}
                </h1>
              </span>
            </Link>
          </li>
          <li
            style={{
              margin: "auto",
            }}
          ></li>
        </ul>
        <h2
          className={
            !options[2]
              ? `f2-theme show-opt-${openMenu}`
              : `f2-theme-spa show-opt-${openMenu}`
          }
          onClick={changeTheme}
        >
          Theme
        </h2>
        <CloseIcon
          className={`close-btn hide-menu-btns-${openMenu}`}
          onClick={onClose}
        />
      </nav>
    </>
  );
};

export default Nav;
