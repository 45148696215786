import React from "react";
import AboutMe from "./cards/AboutCard";
import ProjectsCard from "./cards/ProjectsCard";
import SkillsCard from "./cards/SkillsCard";
import ContactCard from "./cards/ContactCard";
import Nav from "./Nav";
import { useState, useContext } from "react";
import { ThemeContext } from "../contexts/themeContext";
import { headersTxts, footnoteTxts } from "../utils/Texts";

function PortfolioScroll({ onClose }) {
  const { options } = useContext(ThemeContext);
  const [page, setPage] = useState("about");
  const [openMenu, setOpenMenu] = useState(false);
  const showMenu = () => {
    setOpenMenu(!openMenu);
  };
  const changePage = (p) => {
    setPage(p);
  };
  /* █ ▐ ▉ ■  ▇ ▋ ▇  ▖ ▇*/
  return (
    <>
      <div className="portfolio-card">
        <Nav
          openMenu={openMenu}
          onClose={onClose}
          onChange={changePage}
          page={page}
          showMenu={showMenu}
        />
        <div className={`portfolio-section open-menu-${openMenu}`}>
          <div id="about" className="headers">
            {!options[2] ? headersTxts.about.eng : headersTxts.about.spa}{" "}
            <span className="caret-blinking">█</span>
          </div>
          <section className="scrollable-section">
            <AboutMe />
          </section>
          <div id="projects" className="headers">
            {!options[2] ? headersTxts.projects.eng : headersTxts.projects.spa}
            <span className="caret-blinking">█</span>
          </div>
          <section className="scrollable-section">
            <ProjectsCard />
          </section>
          <div id="skills" className="headers">
            {!options[2] ? headersTxts.skills.eng : headersTxts.skills.spa}
            <span className="caret-blinking">█</span>
          </div>
          <section className="scrollable-section">
            <SkillsCard />
          </section>
          <div id="contact" className="headers">
            {!options[2] ? headersTxts.contact.eng : headersTxts.contact.spa}
            <span className="caret-blinking">█</span>
          </div>
          <section className="scrollable-section">
            <ContactCard />
          </section>
          <h3 className="footnote">
            {!options[2] ? footnoteTxts.eng : footnoteTxts.spa}
          </h3>
        </div>
      </div>
    </>
  );
}

export default PortfolioScroll;
