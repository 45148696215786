import React from "react";
import About from "./About";
import Projects from "./Projects";
import Skills from "./Skills";
import Nav from "./Nav";
import { useState, useContext } from "react";
import Contact from "./Contact";
import { ThemeContext } from "../contexts/themeContext";
import { footnoteTxts } from "../utils/Texts";

function Portfolio({ onClose }) {
  const { options } = useContext(ThemeContext);
  const [page, setPage] = useState("about");
  const [openMenu, setOpenMenu] = useState(false);
  const showMenu = () => {
    setOpenMenu(!openMenu);
  };
  const changePage = (p) => {
    setPage(p);
  };

  return (
    <>
      <div className="portfolio-card">
        <Nav
          openMenu={openMenu}
          onClose={onClose}
          onChange={changePage}
          page={page}
          showMenu={showMenu}
        />
        {page === "about" ? <About openMenu={openMenu} /> : ""}
        {page === "projects" ? <Projects openMenu={openMenu} /> : ""}
        {page === "skills" ? <Skills openMenu={openMenu} /> : ""}
        {page === "contact" ? (
          <>
            <Contact openMenu={openMenu} />
            <div className="footnote-section">
              <h3>{!options[2] ? footnoteTxts.eng : footnoteTxts.spa}</h3>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
export default Portfolio;
