import { useContext } from "react";
import { useInView } from "react-intersection-observer";
import { prjTxts } from "../../utils/Texts";
import { ThemeContext } from "../../contexts/themeContext";
import HoverVideoPlayer from "react-hover-video-player";

export default function ProjectsCard() {
  const {
    ref: prjSection,
    inView: prjVisible,
    entry,
  } = useInView({
    threshold: 1,
  });

  const { options, setOptions } = useContext(ThemeContext);
  return (
    <>
      <div className="prj-container" id="projects">
        {prjTxts.map((prj, index) => (
          <div
            ref={index === 0 ? prjSection : null}
            className={prjVisible ? "prj-card swings-in-top-fwd" : "prj-card"}
            key={"div" + index}
          >
            <h2 style={{ zIndex: "101" }}>{prj.name}</h2>
            <div className="prj-img-container">
              <div className="prj-img"></div>
              <HoverVideoPlayer
                videoSrc={require(`../../assets/vids/${prj.vidPath}.mp4`)}
                pausedOverlay={
                  <img
                    src={require(`../../assets/imgs/${prj.imgPath}.png`)}
                    alt=""
                    style={{
                      // Make the image expand to cover the video's dimensions
                      filter: "brightness(50%)",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                }
                restartOnPaused={true}
                loadingOverlay={
                  <div className="loading-overlay">
                    <div className="loading-spinner" />
                  </div>
                }
              />
            </div>
            <p className="prj-row-2">
              {!options[2] ? prj.description : prj.descriptionSp}
            </p>
            {/*  <div className="prj-stack-container"> */}
            <p className="prj-stack-text">{prj.stack}</p>
            {/*  </div> */}
          </div>
        ))}
      </div>
    </>
  );
}
