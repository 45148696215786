import React, { useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import { useInView } from "react-intersection-observer";
import { contactTxts } from "../../utils/Texts";
import { ThemeContext } from "../../contexts/themeContext";
import { FormControl } from "@mui/material";
import { send } from "emailjs-com";

export default function ContactCard() {
  const { ref: contact, inView: contactVisible } = useInView({
    threshold: 1,
    triggerOnce: "true",
  });

  const { options } = useContext(ThemeContext);
  const [formData, setFormData] = useState({
    email: "",
    inputName: "",
    message: "",
  });
  const [inputCbx, setInputCbx] = useState(false);

  const serviceId = process.env.REACT_APP_SERVICE_ID;
  const templateId = process.env.REACT_APP_TEMPLATE_ID;
  const userId = process.env.REACT_APP_USER_ID;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
    setInputCbx(!inputCbx);
  };
  const sendEmail = (e) => {
    e.preventDefault();

    send(
      serviceId,
      templateId,
      { ...formData, inputCbx: inputCbx },
      userId
    ).then(
      (result) => {
        setFormData({ email: "", inputName: "", message: "" });
        setInputCbx(false);
        console.log("Thank you for contacting me.");
        /*        setAlertContent({heading: "Thank you for contacting me.", message: "I will respond to your message as soon as I can."})
          setShowAlert(true) */
      },
      (error) => {
        console.log("Something went wrong.");
        /*         setAlertContent({heading: "Something went wrong.", message: error.text})
        setShowAlert(true) */
      }
    );
  };

  return (
    <div
      ref={contact}
      id="contact"
      className={contactVisible ? "contact-card bounce-top" : "contact-card"}
    >
      <div className="panelout my-panel">
        <FormControl component="form">
          <fieldset className="tui-fieldset">
            <legend>
              {!options[2] ? contactTxts.title.eng : contactTxts.title.spa}
            </legend>
            <ul className="contact-input-container">
              <li className="contact-input-item">
                <TextField
                  style={{ marginBottom: "10px" }}
                  inputProps={{ className: "contact-input" }}
                  fullWidth
                  required
                  focused
                  id="inputName"
                  value={formData.inputName}
                  onChange={handleChange}
                  name="name"
                  label={
                    !options[2]
                      ? contactTxts.name.legend.eng
                      : contactTxts.name.legend.spa
                  }
                  placeholder={
                    !options[2]
                      ? contactTxts.name.placeholder.eng
                      : contactTxts.name.placeholder.spa
                  }
                />
              </li>
              <li className="contact-input-item">
                <TextField
                  style={{ marginBottom: "10px" }}
                  inputProps={{ className: "contact-input" }}
                  fullWidth
                  required
                  focused
                  id="email"
                  onChange={handleChange}
                  value={formData.email}
                  name="mail"
                  label={
                    !options[2]
                      ? contactTxts.mail.legend.eng
                      : contactTxts.mail.legend.spa
                  }
                  placeholder={
                    !options[2]
                      ? contactTxts.mail.placeholder.eng
                      : contactTxts.mail.placeholder.spa
                  }
                />
              </li>
            </ul>
            <div>
              <TextField
                className="textfield"
                required
                focused
                id="message"
                onChange={handleChange}
                value={formData.message}
                name="msg"
                label={
                  !options[2]
                    ? contactTxts.msg.legend.eng
                    : contactTxts.msg.legend.spa
                }
                placeholder={
                  !options[2]
                    ? contactTxts.msg.placeholder.eng
                    : contactTxts.msg.placeholder.spa
                }
                fullWidth
                multiline
                rows={8}
                inputProps={{ className: "contact-input" }}
              />
            </div>
            <br />

            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "start",
              }}
            >
              <label className="tui-checkbox">
                <input
                  type="checkbox"
                  id="inputCbx"
                  name="interest"
                  onChange={handleChange}
                  value={inputCbx}
                />
                <span className="tui-cv"></span>
                {!options[2]
                  ? contactTxts.checkbox.eng
                  : contactTxts.checkbox.spa}
              </label>
            </div>
            <br />
            <div className="submit-btn">
              <button className="tui-button pulsate">
                <p onClick={(e) => sendEmail(e)}>
                  {!options[2] ? "SEND" : "ENVIAR"}
                </p>
              </button>
            </div>
          </fieldset>
        </FormControl>
      </div>
      <div></div>
    </div>
  );
}
