import React from 'react';
import "./HiddenPage.css";
const HiddenPage = () => {
    return (
        <div>
            <h1>Hidden Page</h1>
            <p>This is a hidden page.</p>
        </div>
    );
}

export default HiddenPage;
