import React from "react";
import { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../contexts/themeContext";
import { themeSelector, allThemes } from "../theme/themes";

const Intro = ({ onPortfolio }) => {
  const { options, setOptions } = useContext(ThemeContext);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [memoryTest, setMemoryTest] = useState(0);

  useEffect(() => {
    async function makeRequest() {
      await delay(3500);
      if (memoryTest < 65536) {
        const interval = setInterval(() => {
          setMemoryTest((memoryTest) => memoryTest + 128);
        }, 1);
        return () => {
          clearInterval(interval);
        };
      }
    }
    makeRequest();
  }, [memoryTest]);

  var ascii = [
    "██╗     ███╗   ██╗     ██╗      ██████╗ ███████╗ █████╗ ",
    "██║     ████╗  ██║     ██║     ██╔═══██╗╚══███╔╝██╔══██╗",
    "██║     ██╔██╗ ██║     ██║     ██║   ██║  ███╔╝ ███████║",
    "███████╗██║ ╚████║ ██╗ ███████╗╚██████╔╝███████╗██║  ██║",
    "╚══════╝╚═╝  ╚═══╝ ╚═╝ ╚══════╝ ╚═════╝ ╚══════╝╚═╝  ╚═╝",
  ].join("\n");

  return (
    <div className="crt-monitor crt-fx">
      <div className="crt-piece">
        <pre className="text-focus-in ln-loza">
          <code>{ascii}</code>
        </pre>
        <div className="hideMe">
          <p className="processor-fade-in" style={{ marginTop: "4em" }}>
            Intel Pentium-MMX CPU at 133Mhz
            {/* Intel(R) Pentium(R) MMX CPU at 133Mhz */}
            <br />
            <span className="memory memory-fade-in">Memory Test: </span>
            <span className="memory memory-fade-in">
              {memoryTest < 65536 ? memoryTest + "K" : "65536 OK"}
            </span>
          </p>
          <br />
          <p className="loading-ap-fade-in">
            Award Plug and Play BIOS Extension v1.0A
          </p>
          <p className="loading-ap-fade-in">Copyright (C) 2022, Ln.Loza dev</p>
          <br />
          <p className="loading-pf-fade-in">
            Loading Portfolio
            <span className="load-type">
              <span> . . . OK</span>
            </span>
          </p>
        </div>
        <div className="caret-fade-in">_</div>
        <div className="config-container">
          <div className="retro-container pf-config">
            <div className="retro-container-box-1">
              <div>Navigation Style</div>
            </div>
            <div className="retro-container-box-colons">:</div>
            <div className="retro-container-box-2">
              <label className="tui-radio">
                <input
                  type="radio"
                  id="scrollable"
                  name="navstyle"
                  value=""
                  onChange={() => setOptions([0, options[1], options[2]])}
                  defaultChecked
                />
                <span>Scrollable</span>
              </label>
              <label className="tui-radio">
                <input
                  type="radio"
                  id="sections"
                  name="navstyle"
                  value=""
                  onChange={() => setOptions([1, options[1], options[2]])}
                />
                <span>Sections</span>
              </label>
              <div></div>
            </div>
            <div className="retro-container-box-3">
              <div>Theme</div>
            </div>
            <div className="retro-container-box-colons">:</div>
            <div className="retro-container-box-4">
              {/*  */}
              {allThemes.map((theme, index) => (
                <label className="tui-radio" key={index}>
                  <input
                    type="radio"
                    id={theme.themeName}
                    name="theme"
                    value=""
                    onChange={() => {
                      setOptions([options[0], index, options[2]]);
                      themeSelector(index);
                    }}
                    defaultChecked={index == 0 ? true : false}
                  />
                  <span>{theme.themeName}</span>
                </label>
              ))}
            </div>
            <div className="retro-container-box-5">
              <div>Language</div>
            </div>
            <div className="retro-container-box-colons">:</div>
            <div className="retro-container-box-6">
              <label className="tui-radio">
                <input
                  type="radio"
                  id="langEn"
                  name="language"
                  value=""
                  onChange={() => setOptions([options[0], options[1], 0])}
                  defaultChecked
                />
                <span>English</span>
              </label>
              <label className="tui-radio">
                <input
                  type="radio"
                  id="langSp"
                  name="language"
                  value=""
                  onChange={() => setOptions([options[0], options[1], 1])}
                />
                <span>Spanish</span>
              </label>
            </div>
            <div className="retro-container-box-launch">
              <h2 className="launch-glow" onClick={onPortfolio}>
                &gt;&gt; Launch Portfolio &lt;&lt;
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
