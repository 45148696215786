import React from "react";
import AboutMe from "./cards/AboutCard";
import { ThemeContext } from "../contexts/themeContext";
import { headersTxts } from "../utils/Texts";
import { useContext } from "react";

const About = ({ openMenu }) => {
  const { options } = useContext(ThemeContext);
  return (
    <div className={`portfolio-section open-menu-${openMenu}`}>
      <div id="about" className="headers">
        {!options[2] ? headersTxts.about.eng : headersTxts.about.spa}
        <span className="caret-blinking">█</span>
      </div>
      <AboutMe />
    </div>
  );
};

export default About;
