import React from 'react';
import "./QRDoorBell.css";
const QRDoorbell = () => {
    return (
        <div className="doorbell">
            <h1>DING DONG</h1>
            <p>MOFO</p>
        </div>
    );
}

export default QRDoorbell;
